import { CheckIcon, XIcon } from '@heroicons/react/solid';
import React from 'react';
import { Maybe, WebinarExam } from '../../../graphql/__types';

export function WebinarItemExamStatus({
  preTestUrl,
  postTestUrl,
  evaluationUrl,
}: WebinarExam) {
  function renderSymbol(testUrl: Maybe<string> | undefined) {
    if (!!testUrl) return <CheckIcon className="w-4 h-4" />;
    return <XIcon className="w-4 h-4" />;
  }

  return (
    <section className="text-sm mt-5">
      <section className="flex flex-row items-center justify-start space-x-2">
        {renderSymbol(preTestUrl)}
        <span>Pre Test</span>
      </section>
      <section className="flex flex-row items-center justify-start space-x-2">
        {renderSymbol(postTestUrl)}
        <span>Post Test</span>
      </section>
      <section className="flex flex-row items-center justify-start space-x-2">
        {renderSymbol(evaluationUrl)}
        <span>Evaluation</span>
      </section>
    </section>
  );
}
