import React from 'react';
import { LayoutProps } from '../../../interfaces/Props';

interface Props extends LayoutProps {
  animate?: boolean;
}

export function CardWrapper({ children, animate }: Props) {
  return (
    <section
      className={`
								border 
								border-gray-100
								bg-white
								px-6 py-6
								rounded-lg
								shadow
                h-full
                flex
                flex-col
                justify-between

								${
                  animate
                    ? 'cursor-pointer hover:shadow-lg transform hover:-translate-y-2 transition-all duration-150'
                    : ''
                }
							`}
    >
      {children}
    </section>
  );
}
