import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Maybe,
  OptionEnum,
  useGetAllWebinarsLazyQuery,
  Webinar,
  WebinarStatusEnum,
  WebinarTypeEnum,
} from '../../graphql/__types';
import { Button } from '../Shared/Button';
import { WebinarItem } from './WebinarItem';
import { WebinarLoader } from './WebinarLoader';

interface Props {
  location: 'landing' | 'normal' | 'activities';
  count: number;
  isWebinar: OptionEnum;
  status?: WebinarStatusEnum;
  isRecommended?: boolean;
  isJoined?: boolean;
  isReserved?: boolean;
  type?: WebinarTypeEnum;
}

export function WebinarList({
  status,
  count,
  location,
  isRecommended,
  isJoined,
  isWebinar,
  isReserved,
  type,
}: Props) {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [localData, setLocalData] = useState<Maybe<Webinar>[]>([]);
  const [isInitialLoaded, setIsInitialLoaded] = useState<boolean>(false);
  const [isScrollLoading, setIsScrollLoading] = useState<boolean>(false);

  const [fetchData, { loading, error, data }] = useGetAllWebinarsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const observer = useRef();

  const lastItemRef = useCallback(
    (node) => {
      let observerCurrent = observer.current as any;
      if (loading) return;

      if (observerCurrent) observerCurrent.disconnect();
      observerCurrent = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isScrollLoading) {
          if (isInitialLoaded) {
            if (localData.length !== totalRecords) {
              const newOffset = localData.length as number;

              let queries = {};

              if (isRecommended) {
                queries = { ...queries, recommended: OptionEnum.Yes };
              }

              if (isJoined) {
                queries = { ...queries, joined: OptionEnum.Yes };
              }

              if (isReserved) {
                queries = { ...queries, reserved: OptionEnum.Yes };
              }

              setIsScrollLoading(true);
              setOffset(newOffset);

              fetchData({
                variables: {
                  getWebinarsQuery: {
                    webinar: isWebinar,
                    offset: newOffset,
                    pageSize: count,
                    filterType: type,
                    status,
                    ...queries,
                  },
                },
              });
            }
          }
        }
      });
      if (node) observerCurrent.observe(node);
    },
    [localData],
  );

  const loadItems = (cb?: any) => {
    if (!!data && !!data.getWebinars && !!data.getWebinars.data) {
      const items = data.getWebinars.data;

      if (!!items)
        setLocalData((prevData) => {
          if (!!prevData && items.length > 0) {
            return [...prevData, ...items];
          }

          return prevData;
        });

      if (!!data.getWebinars.totalRecords) {
        setTotalRecords(data.getWebinars.totalRecords);
      }

      return cb();
    }

    if (!!data && data.getWebinars === null) {
      setIsInitialLoaded(true);
      setIsInitialLoading(false);
      setLocalData([]);
    }
  };

  const resetState = () => {
    setTotalRecords(0);
    setOffset(0);
    setLocalData([]);
    setIsInitialLoading(false);
    setIsInitialLoaded(false);
  };

  useEffect(() => {
    if (!isInitialLoaded) {
      let queries = {};

      if (isRecommended) {
        queries = { ...queries, recommended: OptionEnum.Yes };
      }

      if (isJoined) {
        queries = { ...queries, joined: OptionEnum.Yes };
      }

      if (isReserved) {
        queries = { ...queries, reserved: OptionEnum.Yes };
      }

      fetchData({
        variables: {
          getWebinarsQuery: {
            webinar: isWebinar,
            offset,
            pageSize: count,
            filterType: type,
            status,
            ...queries,
          },
        },
      });
    }
  }, [isInitialLoaded]);

  useEffect(() => {
    if (!loading && !error) {
      loadItems(() => {
        setIsInitialLoading(false);
        setIsInitialLoaded(true);
        setIsScrollLoading(false);
      });
    }
  }, [loading, error]);

  useEffect(() => {
    if (error) {
      console.error(error);
      setIsInitialLoading(false);
      setIsInitialLoaded(true);
      setIsScrollLoading(false);
      setLocalData([]);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <section className="w-full">
      {isInitialLoading ? (
        <WebinarLoader />
      ) : localData.length > 0 ? (
        <React.Fragment>
          <section className="block">
            <section className="flex flex-wrap flex-row">
              {localData.map((item: Maybe<Webinar>, index) => {
                if (item !== null) {
                  const { id } = item;

                  if (location === 'normal') {
                    if (localData.length === index + 1)
                      return (
                        <div
                          key={id}
                          ref={lastItemRef}
                          className="w-full sm:w-6/12 md:w-6/12 lg:w-3/12 flex flex-col px-2 py-2"
                        >
                          <WebinarItem
                            isWebinar={isWebinar}
                            data={item}
                            isLast
                          />
                        </div>
                      );
                  }

                  return (
                    <WebinarItem isWebinar={isWebinar} key={id} data={item} />
                  );
                }
              })}
            </section>
            {location === 'normal' && isScrollLoading ? (
              <WebinarLoader />
            ) : null}
          </section>

          {location === 'landing' || location === 'activities' ? (
            <section className="mt-5">
              <section className="flex items-center justify-center">
                <Button onClick={() => navigate('/webinars')} primary size="md">
                  Show More →
                </Button>
              </section>
            </section>
          ) : null}
        </React.Fragment>
      ) : (
        <section className="text-center text-lg uppercase">
          No {isWebinar === OptionEnum.Yes ? 'webinars' : 'activities'} yet.
        </section>
      )}
    </section>
  );
}
