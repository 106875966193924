import { CheckIcon, XIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';
import { navigate } from 'gatsby-link';
import React from 'react';
import { OptionEnum, Webinar } from '../../../graphql/__types';
import { CardWrapper } from '../../Shared/Wrapper/CardWrapper';
import { WebinarItemExamStatus } from './WebinarItemExamStatus';

interface Props {
  data: Webinar;
  isWebinar: OptionEnum;
  isLast?: boolean;
}

export function WebinarItem({ data, isLast, isWebinar }: Props) {
  return (
    <article
      className={`${
        isLast
          ? 'h-full'
          : `w-full sm:w-6/12 md:w-6/12 lg:w-3/12 flex flex-col px-2 py-2`
      }`}
      onClick={() =>
        navigate(
          `${isWebinar === OptionEnum.Yes ? '/webinars' : '/activities'}/${
            data.id
          }`,
        )
      }
    >
      <CardWrapper animate>
        <header className="leading-none">
          <h1 className="font-bold">{data.topic}</h1>
          <p className="text-primary-1 font-bold text-sm">
            {dayjs(data.startTime).format('ddd MMM. D, YYYY hh:mmA')}
          </p>
        </header>

        {isWebinar === OptionEnum.Yes ? (
          <WebinarItemExamStatus
            preTestUrl={data.exams?.preTestUrl}
            postTestUrl={data.exams?.postTestUrl}
            evaluationUrl={data.exams?.evaluationUrl}
          />
        ) : (
          <></>
        )}
      </CardWrapper>
    </article>
  );
}
