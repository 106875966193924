import React from 'react';

export function WebinarLoader() {
  return (
    <section className="w-full">
      <section className="animate-pulse">
        <section className="container mx-auto">
          <section className="flex flex-wrap flex-row">
            <section className="my-1 px-1 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:my-3 lg:px-3">
              <article className="h-full px-5 py-3">
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-2" />
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-5" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
              </article>
            </section>
            <section className="my-1 px-1 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:my-3 lg:px-3">
              <article className="h-full px-5 py-3">
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-2" />
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-5" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
              </article>
            </section>
            <section className="my-1 px-1 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:my-3 lg:px-3">
              <article className="h-full px-5 py-3">
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-2" />
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-5" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
              </article>
            </section>
            <section className="my-1 px-1 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 lg:my-3 lg:px-3">
              <article className="h-full px-5 py-3">
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-2" />
                <section className="bg-gray-300 h-3 w-12/12 rounded mb-5" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
                <section className="bg-gray-300 h-2 w-7/12 rounded mb-2" />
              </article>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
